<template>
	<v-app class="projects" v-if="newProject">
		<Navbar :display="false"></Navbar>
		<v-main>
			<v-container>
				<ProjectForm
					:initial-name="name"
					:initial-logo="logo"
					:initialPhoneNumber="phoneNumber"
					:initialWhatsapp="whatsapp"
					:initialPaymentPageLink="paymentPageLink"
					:initialPeriods="periods"
					:initialSocialMedia="socialMedia"
					:initialLeadEmail="leadEmail"
					:initialQAEmail="qaEmail"
					:initialMapUrl="mapUrl"
					:is-edit="isEdit"
				></ProjectForm>
			</v-container>
		</v-main>
	</v-app>

	<v-container fluid v-else>
		<v-skeleton-loader
			v-if="isPageDataLoading"
			class="mx-auto"
			max-width="500"
			type="card"
		></v-skeleton-loader>
		<ProjectForm
			v-else
			:initial-name="name"
			:initialPhoneNumber="phoneNumber"
			:initialWhatsapp="whatsapp"
			:initialPaymentPageLink="paymentPageLink"
			:initial-logo="logo"
			:initialSocialMedia="socialMedia"
			:initialPeriods="periods"
			:initialLeadEmail="leadEmail"
			:initialQAEmail="qaEmail"
			:initialMapUrl="mapUrl"
			:is-edit="isEdit"
		></ProjectForm>
	</v-container>
</template>

<script>
import Navbar from '@/components/Navbar'
import ProjectForm from './components/ProjectForm'

export default {
	data() {
		return {
			name: '',
			leadEmail: null,
			qaEmail: null,
			logo: null,
			altText: '',
			mapUrl: null,
			periods: [],
			phoneNumber: null,
			whatsapp: null,
			paymentPageLink: null,
			socialMedia: {
				facebook: null,
				instagram: null,
				twitter: null,
				youtube: null,
				medium: null,
				linkedIn: null,
			},
			isEdit: false,
			newProject: true,
			isPageDataLoading: false,
		}
	},
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	created() {
		if (this.id) {
			this.isEdit = true
			this.newProject = false
			this.getProject()
		}
	},
	components: {
		Navbar,
		ProjectForm,
	},
	methods: {
		getProject() {
			if (this.isEdit) {
				this.isPageDataLoading = true
				const projectId = this.$store.state.app.projectId

				this.axios.get(`/project/${projectId}`).then((res) => {
					const data = res.data
					this.name = data.name
					this.phoneNumber = data.phoneNumber
					this.whatsapp = data.whatsapp
					this.paymentPageLink = data.paymentPageLink
					this.mapUrl = data.mapUrl ? data.mapUrl : null
					this.leadEmail = data.leadEmail ? data.leadEmail : null
					this.qaEmail = data.qaEmail ? data.qaEmail : null
					this.socialMedia = data.socialMedia
						? data.socialMedia
						: null
					this.periods = data.periods ? data.periods : null
					this.logo =
						data.logo && data.logo.publicURL ? data.logo : null
					this.altText =
						data.logo && data.logo.altText
							? data.logo.altText
							: null
					this.isPageDataLoading = false
				})
			}
		},
	},
}
</script>
