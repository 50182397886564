<template>
	<v-row justify="center" class="mx-0">
		<v-card class="mb-0 pa-6">
			<!-- Project Logo Upload -->
			<v-row class="mx-0">
				<v-col cols="12" class="pa-0">
					<FileUploader
						:initFiles="logo ? [logo] : []"
						title="Upload Your Business Logo"
						subtitle="Recommended format: .png, .jpg, .jpeg"
						acceptedFiles=".png, .jpg, .jpeg"
						@errorOccured="dropzoneError"
						:purpose="`logo`"
						:maxFiles="1"
						@changed="fileUploaderChanged"
					/>
				</v-col>
			</v-row>

			<v-form @submit.prevent="validateData">
				<!-- Project Name -->
				<v-row class="mx-0">
					<v-col cols="12" class="pa-0">
						<p class="mb-3 label">Business name</p>
						<v-text-field
							placeholder="Eg. Willy Wonka Factory"
							solo
							flat
							v-model="name"
							outlined
							:error-messages="errors.name"
							@change="altTextChanged()"
						></v-text-field>
					</v-col>
					<!-- Phone Number -->
					<v-col cols="6" class="pa-0 pr-2">
						<p class="ma-0 mb-3 label">Phone</p>
						<v-text-field
							placeholder="Eg. +918888888888"
							solo
							flat
							dense
							outlined
							hint="Please enter with international code starting with `+`"
							v-model="phoneNumber"
						></v-text-field>
					</v-col>

					<!-- Whatsapp Number -->
					<v-col cols="6" class="pa-0 pl-2">
						<p class="ma-0 mb-3 label">WhatsApp</p>
						<v-text-field
							placeholder="Eg. +918888888888"
							solo
							flat
							dense
							outlined
							hint="Please enter with international code starting with `+`"
							v-model="whatsapp"
						></v-text-field>
					</v-col>

					<!-- Email for Lead notifications -->
					<v-col cols="6" class="pa-0 pr-2">
						<p class="ma-0 mb-3 label">
							Email to receive Lead notifications
						</p>
						<v-text-field
							placeholder="Eg. john@example.com"
							solo
							flat
							dense
							v-model="leadEmail"
							outlined
							:error-messages="errors.email"
						></v-text-field>
					</v-col>

					<!-- Email for Q&A notifications -->
					<v-col cols="6" class="pa-0 pl-2">
						<p class="ma-0 mb-3 label">
							Email to receive Q&A notifications
						</p>
						<v-text-field
							placeholder="Eg. john@example.com"
							solo
							flat
							dense
							v-model="qaEmail"
							outlined
							:error-messages="errors.email"
						></v-text-field>
					</v-col>

					<!-- External Payment Link -->
					<v-col cols="12" class="pa-0">
						<p class="ma-0 mb-3 label">Payment Page Link:</p>
						<v-text-field
							placeholder="Eg. https://pages.razorpay.com/barioss"
							solo
							flat
							dense
							v-model="paymentPageLink"
							outlined
							hint="Enter payment link"
							:error-messages="errors.email"
						></v-text-field>
					</v-col>

					<!-- Social Media Link -->
					<v-col cols="12" class="pa-0">
						<p class="ma-0 label">Social Media Links</p>
						<v-row>
							<v-col cols="6" class="px-0 pr-4">
								<v-text-field
									label="Facebook"
									v-model="socialMedia.facebook"
									prepend-icon="mdi-facebook"
								></v-text-field>
								<v-text-field
									label="Instagram"
									v-model="socialMedia.instagram"
									prepend-icon="mdi-instagram"
								></v-text-field>
								<v-text-field
									label="Twitter"
									v-model="socialMedia.twitter"
									prepend-icon="mdi-twitter"
								></v-text-field>
							</v-col>

							<v-col cols="6" class="px-0 pl-4">
								<v-text-field
									label="Youtube"
									v-model="socialMedia.youtube"
									prepend-icon="mdi-youtube"
								></v-text-field>
								<v-text-field
									label="Medium"
									v-model="socialMedia.medium"
									prepend-icon="mdi-alpha-m-box"
								></v-text-field>
								<v-text-field
									label="LinkedIn"
									v-model="socialMedia.linkedIn"
									prepend-icon="mdi-linkedin"
								></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-card-actions class="card-actions px-0">
					<UnoSaveButton
						@click.native="validateData()"
						@primary-button-click="projectListing()"
						primary-button-text="Move To Project Listing"
						secondary-button-text="Continue Editing"
						:alert="alert"
						:saving="isSaveButtonLoading"
					></UnoSaveButton>

					<v-spacer> </v-spacer>

					<v-btn
						class="ma-2"
						outlined
						color="indigo"
						@click="
							showBusinessHoursDialog = !showBusinessHoursDialog
						"
					>
						Business Hours
					</v-btn>

					<v-btn
						class="ma-2"
						outlined
						color="indigo"
						@click="showMapUrlDialog = !showMapUrlDialog"
					>
						Business Location
					</v-btn>
				</v-card-actions>
			</v-form>

			<!-- Bussiness Hours Form Dialogue -->
			<BusinessHoursCard
				:periods="initialPeriods"
				v-if="showBusinessHoursDialog"
				:dialog="showBusinessHoursDialog"
				@submit="populateBusinessHours($event)"
				@closeModal="showBusinessHoursDialog = !showBusinessHoursDialog"
			>
			</BusinessHoursCard>

			<!-- Businness Location Map Dialogue -->
			<v-dialog
				v-model="showMapUrlDialog"
				max-width="400px"
				v-if="showMapUrlDialog"
			>
				<v-card
					min-width="400px"
					class="pa-3 mb-0 d-flex flex-column justify-center"
				>
					<v-text-field
						v-model="embeddedMapUrl"
						outlined
						dense
						placeholder="Enter your embedded map link"
						@keyup="getMapLinkSrc()"
						:error-messages="errors.embeddedMapLink"
					></v-text-field>
				</v-card>
				<v-card min-width="400px" class="pa-3 mb-0 d-flex flex-column">
					<iframe
						v-if="mapUrl"
						:src="mapUrl"
						frameborder="0"
						width="100%"
						height="100%"
					></iframe>
					<v-btn
						:disabled="embeddedMapUrl ? false : true"
						@click="
							() => {
								selectLocation = true
								showMapUrlDialog = false
							}
						"
						color="primary"
					>
						Select
					</v-btn>
				</v-card>
			</v-dialog>
		</v-card>
	</v-row>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import BusinessHoursCard from './BusinessHoursCard'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'

export default {
	data() {
		return {
			name: '',
			leadEmail: '',
			qaEmail: '',
			phoneNumber: '',
			whatsapp: '',
			businessHours: [],
			logo: null,
			logoId: null,
			altText: null,
			embeddedMapUrl: null,
			selectLocation: false,
			mapUrl: null,
			socialMedia: {
				facebook: null,
				instagram: null,
				twitter: null,
				youtube: null,
				medium: null,
				linkedIn: null,
			},
			showBusinessHoursDialog: false,
			showMapUrlDialog: false,
			isSaveButtonLoading: false,
			isEditLocal: this.isEdit,
			errors: {
				name: '',
			},
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	props: {
		initialName: {
			type: String,
			required: true,
			default: () => '',
		},
		initialWhatsapp: {
			type: String,
			default: () => null,
		},
		initialPhoneNumber: {
			type: String,
			default: () => null,
		},
		initialLogo: {
			type: Object,
			default: () => null,
		},
		initialPaymentPageLink: {
			type: String,
			default: () => null,
		},
		initialSocialMedia: {
			type: Object,
			default: function () {
				return {
					facebook: null,
					instagram: null,
					twitter: null,
					youtube: null,
					medium: null,
					linkedIn: null,
				}
			},
		},
		initialLeadEmail: {
			type: String,
			default: () => null,
		},
		initialQAEmail: {
			type: String,
			default: () => null,
		},
		initialMapUrl: {
			type: String,
			default: () => null,
		},
		initialPeriods: {
			type: Array,
			default: () => null,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		name() {
			this.errors.name = ''
		},
	},
	created() {
		this.leadEmail = this.initialLeadEmail ? this.initialLeadEmail : null
		this.qaEmail = this.initialQAEmail ? this.initialQAEmail : null
		this.mapUrl = this.initialMapUrl ? this.initialMapUrl : null
		this.name = this.initialName
		this.phoneNumber = this.initialPhoneNumber
		this.whatsapp = this.initialWhatsapp
		this.paymentPageLink = this.initialPaymentPageLink
		this.socialMedia = this.initialSocialMedia
		this.logo = this.initialLogo
		this.logoId = this.initialLogo ? this.initialLogo.media : null
	},
	components: {
		FileUploader,
		UnoSaveButton,
		BusinessHoursCard,
	},
	methods: {
		populateBusinessHours(emittedArray) {
			this.businessHours = emittedArray
		},
		async getMapLinkSrc() {
			let data = this.embeddedMapUrl
			var parser = new DOMParser()
			var parsedIframe = parser.parseFromString(data, 'text/html')
			let iFrame = await parsedIframe.getElementsByTagName('iframe')

			// Read URL:
			if (iFrame.length > 0) {
				this.mapUrl = iFrame[0].src
			} else {
				this.errors.embeddedMapLink =
					'Please enter a valid embedded map link.'
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		altTextChanged() {
			let altText = this.name
			if ((this.logoId && this.name !== '') || null) {
				this.axios({
					method: 'patch',
					url: `/media/${this.logoId}`,
					data: { altText: altText },
				})
					.then((res) => {
						console.log(res.data.message)
					})
					.catch((error) => {
						let errors = error.response.data
						this.alert.message = errors.message
					})
			}
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.logoId = data.id
				this.logo = {
					id: this.logoId,
					publicURL: data.publicURL,
				}
			} else {
				this.logoId = null
				this.logo = null
			}
		},
		validateData() {
			let isValid = true
			if (this.name === '') {
				isValid = false
				this.errors.name = 'Please enter your Business name'
			} else {
				this.errors.name = ''
			}

			if (isValid) {
				this.isSaveButtonLoading = true
				let project = {
					name: this.name,
					leadEmail: this.leadEmail,
					qaEmail: this.qaEmail,
					phoneNumber:
						this.phoneNumber && this.phoneNumber.length > 0
							? this.phoneNumber
							: null,
					whatsapp:
						this.whatsapp && this.whatsapp.length > 0
							? this.whatsapp
							: null,
					paymentPageLink:
						this.paymentPageLink && this.paymentPageLink.length > 0
							? this.paymentPageLink
							: null,
					logo: this.logoId,
					periods: this.businessHours,
					socialMedia: this.socialMedia,
					mapUrl: this.selectLocation ? this.mapUrl : null,
				}
				this.saveProject(project)
			}
		},
		saveProject(projectData) {
			const isEdit = this.isEditLocal
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: isEdit ? 'put' : 'post',
				url: isEdit ? `/project/${projectId}` : '/project',
				data: projectData,
			})
				.then((res) => {
					this.isSaveButtonLoading = false

					if (res.status == 200 && res.data.success) {
						console.log(res.data)
						this.$store.commit('app/setProjectId', res.data.data.id)
						this.name = res.data.data.name
						this.logo = res.data.data.logo
						this.altText =
							res.data.logo && res.data.logo.altText
								? res.data.logo.altText
								: null
						this.periods = res.data.data.periods
							? res.data.data.periods
							: null
						this.socialMedia = res.data.data.socialMedia
						this.mapUrl = res.data.data.mapUrl
							? res.data.data.mapUrl
							: null
						this.leadEmail = res.data.data.leadEmail
						this.qaEmail = res.data.data.qaEmail
						this.alert.message = res.data.message
						this.isEditLocal = true
					}
				})
				.catch((error) => {
					this.isSaveButtonLoading = false
					const { data } = error.response
					if (data.statusCode == 400) {
						this.$snackbar.notify({
							message: data.message,
							color: 'error',
						})
					}
				})
		},
		projectListing() {
			this.$router.push({
				name: 'project.list',
			})
		},
	},
}
</script>

<style scoped>
.card-actions .icon {
	font-size: 17px;
}
.label {
	font-size: 1rem;
	color: black;
}
.v-card {
	max-width: 80%;
	width: 100%;
}
</style>
