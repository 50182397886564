<template>
	<v-dialog v-model="openDialog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Business Hours</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>
			<v-form ref="form" @submit.prevent="submit">
				<v-row class="mx-0">
					<v-col
						cols="12"
						class="
							pa-0
							pr-2
							d-flex
							flex-row
							align-start
							justify-space-between
						"
						v-for="day in days"
						:key="day"
					>
						<p class="pr-3" style="width: 95px">{{ day }}</p>
						<v-switch
							v-model="switches[day]"
							class="ma-0"
						></v-switch>

						<v-row class="mx-0">
							<v-col
								cols="10"
								v-for="time in hours.filter((r) => {
									return r.openDay === day.toUpperCase()
								})"
								:key="time.id"
								class="d-flex flex-row pa-0"
							>
								<v-col cols="6" class="pa-0 pr-2">
									<v-text-field
										v-if="switches[day]"
										v-model="time.openTime"
										color="purple darken-2"
										label="Open Time"
										dense
									></v-text-field>
								</v-col>

								<v-col cols="6" class="pa-0">
									<v-text-field
										v-if="switches[day]"
										v-model="time.closeTime"
										color="purple darken-2"
										label="Close Time"
										dense
									></v-text-field>
								</v-col>
							</v-col>
							<!-- <v-spacer></v-spacer>
							<v-col cols="2" class="pa-0">
								<v-btn
									v-if="switches[day]"
									class="ma-2"
									plain
									color="#1976d2"
									@click="addHours(day)"
								>
									Add hours
								</v-btn>
							</v-col> -->
						</v-row>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-spacer></v-spacer>
					<v-btn
						color="var(--blue)"
						depressed
						type="submit"
						class="save-btn pa-3"
						@click="submit()"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	components: {},
	props: {
		periods: {
			type: Array,
		},
		dialog: {
			type: Boolean,
		},
	},
	data() {
		return {
			days: [
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday',
			],
			switches: {
				Sunday: true,
				Monday: true,
				Tuesday: true,
				Wednesday: true,
				Thursday: true,
				Friday: true,
				Saturday: true,
			},
			hours: [],
			openDialog: false,
			message: {
				error: null,
			},
		}
	},
	created() {
		this.openDialog = this.dialog
		if (this.periods.length > 0) {
			this.hours = this.periods
			this.hours.forEach((period) => {
				if (
					period.openTime === '00:00' &&
					period.closeTime === '00:00'
				) {
					for (var key in this.switches) {
						let day = period.openDay
						if (day === key.toUpperCase())
							this.switches[key] = false
					}
				}
			})
		} else {
			this.days.forEach((day) => {
				let obj = {
					openDay: day.toUpperCase(),
					closeDay: day.toUpperCase(),
					openTime: null,
					closeTime: null,
				}
				this.hours = [...this.hours, obj]
				for (var key in this.switches) {
					this.switches[key] = false
				}
			})
		}
	},
	methods: {
		addHours(day) {
			let obj = {
				openDay: day.toUpperCase(),
				closeDay: day.toUpperCase(),
				openTime: '00:00',
				closeTime: '00:00',
			}
			this.hours = [...this.hours, obj]
		},
		resetValidation() {
			this.$emit('closeModal')
		},
		submit() {
			let emittedHours = this.hours
			this.hours.forEach((period) => {
				if (
					(period.openTime === '00:00' &&
						period.closeTime !== '00:00') ||
					(period.openTime !== '00:00' &&
						period.closeTime === '00:00')
				) {
					this.message.error = 'Please fill correct time slot'
					console.log('Invalid Time Slot')
				} else {
					for (var key in this.switches) {
						if (this.switches[key] === false) {
							this.hours = emittedHours.filter((day) => {
								if (day.openDay === key.toUpperCase()) {
									let index = emittedHours.indexOf(day)
									emittedHours[index] = {
										openDay: key.toUpperCase(),
										closeDay: key.toUpperCase(),
										openTime: '00:00',
										closeTime: '00:00',
									}
								}
								return emittedHours
							})
						}
					}
					this.$emit('submit', emittedHours)
					this.$emit('closeModal')
				}
			})
		},
	},
}
</script>
